import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const alertCarousels = document.querySelectorAll('.alert__carrousel');

  if (0 < alertCarousels.length) {
    alertCarousels.forEach(function (alertCarousel) {
      const swiperConfig = {
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      };

      new Swiper(alertCarousel.querySelector('.alert-swiper'), swiperConfig);
    });
  }
});
